import React from "react";
import Header from "../Elements/Header";
import Carousel from "../Elements/Carousel";

function TimeLabs() {
  return (
    <React.Fragment>
      <Header background={`url('${process.env.PUBLIC_URL}/TimeLabs/Background.PNG')`}>
        <h1>Time Labs</h1>
        <p>Can humans control time flow? Newly discovered time-distorting crystals might be a solution. But something went wrong... After waking up in the middle of destroyed laboratories, you must discover what happened. How much time has passed? Why is nobody around? Welcome to TimeLabs.</p>
        <p>TimeLabs is a first person puzzle game. You need to manipulate time in your favor. It slows down or speeds up near special crystals. Both you and surrounding environment are affected. Lights can start blinking, sound gets distorted, machinery may stop working. Progress through TimeLabs and discover the dangers of manipulating time! </p>
      </Header>

      <div className="Section s1">
        <div className="Section-item">
          <Carousel images= {[
            "TimeLabs/Carousel1.gif",
            "TimeLabs/Carousel2.gif",
            "TimeLabs/Carousel3.gif",
            "TimeLabs/Carousel4.gif",
            "TimeLabs/Carousel5.PNG",
            "TimeLabs/Carousel6.PNG",
            "TimeLabs/Carousel7.PNG"
          ]}/>
        </div>
      </div>
      <div className="Section Primary s1">
        <div className="Section-item Text-center">
          <p>TimeLabs was created as Engineering Thesis of two of our programming team members as well as a Master Thesis in sound design/music of our music director. Prototype not released publicly, although whole project will be someday completed and released. Special thanks to Witold Andrzejewski for accepting the project and Poznań University of Technology for educating our team members. </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TimeLabs;