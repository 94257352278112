import React from "react";

import SectionTitle from "../Elements/SectionTitle";
import ProjectThumb from "../Elements/ProjectThumb";
import Header from "../Elements/Header";
import Icon from '@mdi/react';
import { mdiFacebook, mdiTwitter, mdiInstagram, mdiSoundcloud, mdiYoutube } from '@mdi/js';

function Home() {
  return (
    <React.Fragment>
      <Header background={`url('${process.env.PUBLIC_URL}/Background.jpg')`}>
        <h1>
          Suicide Penguin
        </h1>
        <h5>
          Bashing its head againt the rock
        </h5>
      </Header>

      <SectionTitle>ABOUT US</SectionTitle>

      <div className="Section s2">
        <div className="Section-item Text-center">
          <p>We are a group of friends working together on video games. We know each other for a long time, two of us met as far as 14 years ago. Despite having a wide range of interests, we were drawn together by computer games. Years of endless discussions about various game ideas pushed us towards gaining necessary skills to create them. After gaining enough knowledge through education and loads of failed experiments, we decided to actually start a games development studio together. As a complete and diverse team composed of two programmers, two graphical artists and sound designer/composer, we believe that we are ready to share our ideas with players around the world.</p>
          <p><strong>Suicide Penguin Studio</strong> has been formed somewhere around year 2013. We've done few concept projects so far, mostly as part of our university graduation projects. If you would like to see some cool ideas we had, several examples are presented below. </p>
        </div>
        <div className="Section-item">
          <img className="TeamPhoto" src="TeamPhoto.jpg"/>
        </div>
      </div>

      <SectionTitle>OUR PROJECTS</SectionTitle>

      <div className="Section s3">
        <ProjectThumb name="Rage Disco" image="RageDisco/Thumb.jpg" link="rage-disco"/>
        <ProjectThumb name="Red Hunt" image="RedHunt/Thumb.gif" link="red-hunt"/>
        <ProjectThumb name="Time Labs" image="TimeLabs/Thumb.jpg" link="time-labs"/>
      </div>

      <div className="Section s5 Bg-light Footer Center">
        <div className="Section-item Center">
          <a href="https://www.facebook.com/suicidepenguinstudio">
            <button className="Button Socials-button">
              <div className="Socials-icon"><Icon path={mdiFacebook} size={1.5}/></div>
              <div className="Socials-name">Facebook</div>
            </button>
          </a>
        </div>
        <div className="Section-item Center">
          <a href="https://www.youtube.com/channel/UChTyO0R1ZS5GV4pzWp3F_6A">
            <button className="Button Socials-button">
              <div className="Socials-icon"><Icon path={mdiYoutube} size={1.5}/></div>
              <div className="Socials-name">Youtube</div>
            </button>
          </a>
        </div>
        <div className="Section-item Center">
          <a href="https://soundcloud.com/suicidepenguinstudio">
            <button className="Button Socials-button">
              <div className="Socials-icon"><Icon path={mdiSoundcloud} size={1.5}/></div>
              <div className="Socials-name">Soundcloud</div>
            </button>
          </a>
        </div>
        <div className="Section-item Center">
          <a href="https://www.instagram.com/suicidepenguinstudio/">
            <button className="Button Socials-button">
              <div className="Socials-icon"><Icon path={mdiInstagram} size={1.5}/></div>
              <div className="Socials-name">Instagram</div>
            </button>
          </a>
        </div>
        <div className="Section-item Center">
          <a href="https://twitter.com/suicide_penguin">
            <button className="Button Socials-button">
              <div className="Socials-icon"><Icon path={mdiTwitter} size={1.5}/></div>
              <div className="Socials-name">Twitter</div>
            </button>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;