function SectionTitle (props: {children: any}) {

  return(
    <div className="Section s1 Primary">
    <div className="Section-centered Section-item">
      <h1>{props.children}</h1>
    </div>
  </div>
  );
}

export default SectionTitle;