import { Link } from "react-router-dom";

function ProjectThumb(props: {
  name: string,
  image: string,
  link: string
}) {
  return(
    <div className="Section-item">
      <Link to={props.link}>
        <div className="ProjectThumb">
          <div className="Center">
            <img className="ProjectThumb-image" src={props.image}/>
          </div>
          <div className="Center">
            FIND OUT MORE
          </div>
        </div>
      </Link>
  </div>
  )
}

export default ProjectThumb;