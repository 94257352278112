import './Header.css';

function Header(props: {
  background: any;
  children?: any;
}) {
  return (
  <div className="Section s1 Header" style={{backgroundImage: props.background}}>
    <div className="Section-centered Text-center Text-primary Section-item">
      {props.children}
    </div>
  </div>
  );
}

export default Header;