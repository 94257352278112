import React from "react";
import Header from "../Elements/Header";
import Carousel from "../Elements/Carousel";

function RedHunt() {
  return (
    <React.Fragment>
      <Header background={`url('${process.env.PUBLIC_URL}/RedHunt/Background.jpg')`}>
        <h1>Red Hunt</h1>
        <p>In a cabin deep in the forest, Red wakes up. Mother is gone and someone tries to get inside through main door. After several seconds, a werewolf gets inside the cabin. Red runs away in fear trying to survive. Join her adventures, find a weapon and her mother. Perhaps grandma or villagers know something about the dangerous events that are happening.</p>
        <p>Red Hunt is a dynamic action game. Werewolves are trying to kill you and they become very powerful during the night. Do as much as you can during the day, try to survive when the night comes. Each of your weapons weakens enemies whilst at the same time support you with a buff. Be quick and smart switching them out and you might be able to survive every encounter. Do not rush during the night because werewolves are superior with their vision and increased stamina. Good luck.</p>
      </Header>

      <div className="Section s1">
        <div className="Section-item">
          <Carousel images= {[
            "RedHunt/Carousel1.PNG",
            "RedHunt/Carousel2.PNG",
            "RedHunt/Carousel3.jpg",
            "RedHunt/Carousel4.jpg",
            "RedHunt/Carousel5.jpg",
            "RedHunt/Carousel6.jpg",
            "RedHunt/Carousel7.PNG"
          ]}/>
        </div>
      </div>
      <div className="Section Primary s1">
        <div className="Section-item Text-center">
          <p>Project was done as a master degree graduation project of our team member, Mikołaj "msk" Saar-Kozłowski. Created in Unreal Engine 4 with support of our other team members, we got a prototype ready in a year. Project has been cancelled due to the size of a whole concept. Perhaps one day we might complete it...</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RedHunt;