import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";
import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';
import './NavBar.css';

function NavBar() {
  const [isHamborgirOpen, setIsHamborgirOpen] = useState(false);

  const onTriggerClicked = () => {
    setIsHamborgirOpen(!isHamborgirOpen);
  }

  const closeHamborgir = () => {
    setIsHamborgirOpen(false);
  }

  return (
    <React.Fragment>
      <div className={isHamborgirOpen ? "NavBar-overlay" : ""}></div>
      <div className="NavBar">
        <div className="NavBar-brand">
          <Link to="/">
            <img src="SuicidePenguin-logo.png"/>
          </Link>
        </div>
        <div className="NavBar-navigation noselect">
            <div className="NavBar-navigation-trigger" onClick={onTriggerClicked}><Icon path={mdiMenu} size={1.5}/></div>
            <div className={"NavBar-navigation-links"+(isHamborgirOpen?" HamborgirOpen":"")}>
              <Link to="/"><div className="NavBar-navigation-item" onClick={closeHamborgir}>Home</div></Link>
              <Link to="/time-labs"><div className="NavBar-navigation-item" onClick={closeHamborgir}>Time Labs</div></Link>
              <Link to="/red-hunt"><div className="NavBar-navigation-item" onClick={closeHamborgir}>Red Hunt</div></Link>
              <Link to="/rage-disco"><div className="NavBar-navigation-item" onClick={closeHamborgir}>Rage Disco</div></Link>
            </div>
        </div>
      </div>
      <div className="NavBar-placeholder"></div>
    </React.Fragment>
  )
}

export default NavBar;