import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import NavBar from './Elements/NavBar';
import Home from './Pages/Home';
import RageDisco from './Pages/RageDisco';
import RedHunt from './Pages/RedHunt';
import TimeLabs from './Pages/TimeLabs';

function App() {
  return (
    <React.Fragment>
      <Router>
        <NavBar/>
        <Routes>
          <Route path="/rage-disco" element={<RageDisco/>}/>
          <Route path="/red-hunt" element={<RedHunt/>}/>
          <Route path="/time-labs" element={<TimeLabs/>}/>
          <Route path="/" element={<Home/>}/>
          <Route path="/*" element={<Home/>}/>
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
