import React from "react";
import Header from "../Elements/Header";
import Carousel from "../Elements/Carousel";

function RageDisco() {
  return (
    <React.Fragment>
      <Header background={`url('${process.env.PUBLIC_URL}/RageDisco/Background.jpg')`}>
        <h1>Rage Disco</h1>
        <p>Rage Disco is an intense action game where psychedelic substances drive a randomly evolving party filled with deadly, strobing lasers.</p>
        <p>Mind-bending pills crank up all the crazy events going down on the dance floor. They cause surreal visuals and may affect movement, among many other effects. It is a randomly generated party where areas, enemies and custom-made music are transforming based upon your actions. Blazing, vibrant lasers and flashy enemies are a mortal danger, especially when your senses are completely overwhelmed. </p>.
      </Header>

      <div className="Section s1">
        <div className="Section-item">
        <Carousel images= {[
          "RageDisco/Carousel1.jpg",
          "RageDisco/Carousel2.jpg",
          "RageDisco/Carousel3.jpg",
          "RageDisco/Carousel4.jpg",
          "RageDisco/Carousel5.jpg",
          "RageDisco/Carousel6.jpg"
        ]}/>
        </div>
      </div>
      <div className="Section Primary s1">
        <div className="Section-item Text-center">
          <p>Rage Disco was never released despite the three years long development time. In year 2018 project was suspended for an indefinite period.</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RageDisco;